
    .el-breadcrumb {
        margin: 0 0 14px 35px;
        .el-breadcrumb-first {
            ::v-deep .el-breadcrumb__inner {
                color: #BBB9CB;
            }
        }
        .el-breadcrumb-second {
            ::v-deep .el-breadcrumb__inner {
                color: #5F5B74;
            }
        }
    }
    .video-management-content {
        background-color: #fff;
        /*box-shadow: 0 0 16px 1px rgba(11, 11, 28, 0.09);*/
        /*border-radius: 10px;*/
        padding: 35px 0 0 0;
        .video-management-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            border-bottom: 1px solid #EBEBF9;
            margin: 0 35px;
            padding-bottom: 19px;
            .publish-video {
                background-color: #594FED;
                border-color: #594FED;
                border-radius: 15px;
                &:hover {
                    background-color: #463bdc;
                    border-color: #463bdc;
                }
            }
        }
        .select-content {
          
      
            padding: 0 35px 0 45px;
          
            /*padding-left: 10px;*/
            .item {
              
                // background: skyblue;
                display: flex;
                align-items: center;
                padding: 0 10px;
                margin: 20px 0 20px 0;
                color: #666;
            }
            .sort-item {
                display: inline-block;
                padding: 2px 6px;
                margin: 0 5px;
            }
            .sort-item.current, .sort-item:hover {
                background: #9ACC5B;
                color: #fff;
                border-radius: 5px;
                cursor: pointer;
            }
            .images{
                width: calc(100vw - 200px);
                padding-bottom: 10px;
                display: flex;
                overflow-x: auto;
                overflow-y: hidden;
                
            }
            .sort-text{
                flex-shrink: 0;
            }
            .img-item {
                background: transparent !important;
                width: 38px;
                height: 38px;
                border-radius: 50% !important;
                margin-right: 20px;
                flex-shrink: 0;
                img.type-img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
                img.img-item-selected {
                    display: none;
                    position: relative;
                    top: -15px;
                    left: 27px;
                    width: 14px;
                    height: 14px;
                }
            }
            .current {
                img.img-item-selected {
                    display: block;
                }
            }
        }
        .table-bin {
            padding: 0 45px 0 55px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            ::v-deep .el-button--text {
                color: #666;
                font-size: 14px;
                i {
                    margin-right: 5px;
                }
            }
            ::v-deep .el-input {
                width: 270px;
                .el-input__inner {
                    border-radius: 15px;
                    border: 1px solid #594FEE;
                }
            }
            ::v-deep .el-input-group__append {
                background-color: transparent;
                border: none;
                left: -55px;
            }
        }
        .my-video-table {
            ::v-deep td, th {
                padding: 5px 0;
            }
            ::v-deep .el-table__body-wrapper {
                .el-table__header {
                    th {
                        padding: 5px 0;
                        .cell {
                            margin-left: 5px;
                        }
                    }
                }
                .el-table__body {
                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #9ACC5B;
                        border-color: #9ACC5B;
                    }
                    .el-checkbox__inner:hover {
                        border-color: #9ACC5B;
                    }
                    ::v-deep .el-table__row {
                        td:nth-child(2) .cell {
                            display: flex;
                            justify-content: start;
                            align-items: center;
                            cursor: pointer;
                        }
                    }
                }
            }
            .video-cover {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                min-width: 50px;
                height: 40px;
                margin: 0 10px 0 0;
                padding: 0;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .video-btn {
                color: #685DF7;
                margin-right: 10px;
                &:hover {
                    color: #2338E6;
                }
            }
            .del-btn {
                color: #FB0338;
                margin-left: 10px;
                &:hover {
                    color: #CD062E;
                }
            }
        }
        .pages-center {
            ::v-deep .el-pager li {
                background-color: transparent !important;
                width: 24px;
                min-width: 24px;
                height: 24px;
                line-height: 26px;
            }
            ::v-deep .el-pager li:not(.disabled).active {
                background-color: #564CED !important;
                border-radius: 50%;
                color: #fff;
            }
        }
        .VideoDetail-dialog {
            .video-detail {
                /*height: 700px;*/

            }
            ::v-deep .el-dialog {
                border-radius: 10px;
                .el-dialog__header {
                    background-color: #F1F0FE;
                    border-radius: 10px 10px 0 0;
                }
                .el-dialog__body {
                    padding: 20px;
                }
                .el-dialog__footer {
                    text-align: center;
                    .dialog-footer {
                        .el-button {
                            padding: 7px 28px;
                            border-radius: 15px;
                        }
                        .determine-btn {
                            background: #594FEE;
                            border-color: #594FEE;
                            &:hover {
                                background: #2338E6;
                                border-color: #2338E6;
                            }
                        }
                    }
                }
                .header {
                    display: flex;
                    .video-cover {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 300px;
                        height: 200px;
                        margin-right: 20px;
                        cursor: pointer;
                        img.cover {
                           max-width: 100%;
                            max-height: 100%;
                        }
                        img.play-btn {
                            position: absolute;
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .user-info {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        width: calc(100% - 320px);
                        .user-avatar {
                            display: flex;
                            .avatar {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 80px;
                                height: 80px;
                                margin-right: 20px;
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                    border-radius: 50%;
                                }
                            }
                            .users-name {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .nickname {
                                    color: #16113F;
                                    font-size: 24px;
                                    font-weight: 500;
                                }
                                .douyin {
                                    color: #16113F;
                                    font-size: 16px;
                                    font-weight: 400;
                                }
                            }
                        }
                        .video-name {
                            margin-top: 20px;
                            color: #16113F;
                            font-size: 18px;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 100%;
                        }
                    }
                }
                .VideoDetail-btn {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 20px 0;
                    .play-comment-like {
                        color: #594FEE;
                        .item {
                            margin-right: 20px;
                        }
                    }
                    .btn-del-video {
                        color: #FB0360;
                        i {
                            margin-right: 10px;
                            font-size: 14px;
                        }
                    }
                }
                .operating-video {
                    color: #5F5C74;
                }
                .comment-content {
                    margin: 20px 0;
                    border-top: 1px solid #EBEBF9;
                    .comment-header {
                        color: #5F5C74;
                        font-size: 16px;
                        margin: 20px 0;
                        display: block;
                    }
                    .comment {
                        height: 182px;
                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                        span.no-data {
                            display: block;
                            text-align: center;
                        }
                        .commentator {
                            margin-bottom: 20px;
                            .user-info {
                                display: flex;
                                align-items: center;
                                margin-left: 20px;
                                img {
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 50%;
                                    margin-right: 10px;
                                }
                                .name {
                                    color: #5F5C74;
                                }
                            }
                            .content {
                                margin-left: 60px;
                                display: flex;
                                flex-direction: column;
                                line-height: 1.6;
                                .detail {
                                    color: #16113F;
                                    font-size: 12px;
                                }
                                .publish-info {
                                    color: #BCB9CB;
                                    font-size: 12px;
                                }
                            }
                        }
                        .reply {
                            margin-left: 60px;
                            margin-bottom: 10px;
                            line-height: 1.6;
                            .user-info {
                                display: flex;
                                align-items: center;
                                img {
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    margin-right: 10px;
                                }
                                .name {
                                    color: #5F5C74;
                                    font-size: 12px;
                                    margin-right: 20px;
                                }
                                .content {
                                    color: #16113F;
                                    font-size: 12px;
                                }
                            }
                            .publish-time {
                                color: #BCB9CB;
                                font-size: 12px;
                                margin-left: 30px;
                            }
                        }
                    }
                }
                .did-not-pass {
                    color: #FB0338;
                    .time {
                        margin-bottom: 10px;
                        span:nth-child(1) {
                            margin-right: 20px;
                        }
                    }
                    .detail {
                        span:nth-child(1) {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
        .PublishVideo-dialog {
            ::v-deep .el-dialog__body {
                height: 126px;
                .type {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .el-radio__input.is-checked .el-radio__inner {
                         border-color: #DCDFE6;
                        background: transparent;
                    }
                    .el-radio__input.is-checked .el-radio__inner::after {
                        width: 6px;
                        height: 6px;
                        background-color: #9ACC5B;
                    }
                    .el-radio__input.is-checked+.el-radio__label {
                        color: #606266;
                    }
                }
            }
        }
    }
    .view-more {
        display: block;
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
        cursor: pointer;
        &:hover {
            color: #2338E6;
        }
    }
    .no-more {
        display: block;
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
    }
    .view-more-reply {
        display: block;
        width: 250px;
        font-size: 12px;
        text-align: center;
        padding-bottom: 10px;
        cursor: pointer;
        &:hover {
            color: #2338E6;
        }
    }
    .no-more-reply {
        display: block;
        width: 250px;
        font-size: 12px;
        text-align: center;
        padding-bottom: 10px;
    }


    /* 设置滚动条整体 */
::-webkit-scrollbar {
  width: 2px; /* 滚动条宽度 */
  height: 4px; /* 水平滚动条高度 */
}

/* 设置滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 轨道背景色 */
}

/* 设置滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #888; /* 滑块背景色 */
  border-radius: 6px; /* 滑块圆角 */
}

/* 设置滚动条滑块悬停时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* 滑块悬停背景色 */
}
